.filmContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('../../../public/img/bg.webp');
    background-size: cover;
    background-repeat: no-repeat;
}



.filmWrapper {
    width: 900px;
    background-color: white;
    padding: 20px;
    padding-bottom: 200px;
    display: flex;
    flex-direction: column;
    padding-top: 50px;
}

.top {
    display: flex;
    gap: 30px;
}

.filmImage {
    flex: 1;
    width: 50%;
    object-fit: cover;
}

.filmInfo {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.title{
    font-size: 30px;
    font-weight: 700;
    color: #3b7ac7;
    margin-bottom: 5px;
}

.info{
    font-size: 14px;
    color: #66788f;
    margin-bottom: 5px;
}

.synopcisTitle{
    color: #66788f;
    margin-bottom: 10px;
}

.synopcis{
    line-height: 1.5;
}

.bottom{
    margin-top: 50px;
    width: max-content;
}

.videoLink{
    position: relative;
    width: 500px;
    height: 300px;
    
}



.backgroundVideo{
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    position: absolute;
    z-index: 2;
    transition: all 300ms ease;
    
}

.videoLink:hover .backgroundVideo{
    background-color: rgba(0, 0, 0, 0.7);
}

.videoLink:hover .playIcon{
    color: white;
}

.videoImg{
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
    
}

.playIcon{
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    color:rgb(187, 186, 186);
    z-index: 3;
    transition: all 300ms ease;
}

.infoItem{
    display: flex;
    margin-bottom: 5px;
}

.info1{
    font-size: 14px;
    color: #66788f;
    width: 80px;
}

.info2{
    font-size: 14px;
    color: #798da7;
}