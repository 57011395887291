.contactContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('../../../public/img/bg.webp');
    background-size: cover;
    background-repeat: no-repeat;
}

.wrapper{
    width: 900px;
    height: 500px;
    background-color: white;
    padding: 50px;
    display: flex;
}

.cordonner{
    flex: 1;
}

.listCordonner{
    list-style: none;
}

.listItem{
    display: flex;
    padding: 20px 0px;
}

.icon{
    color: rgb(151, 129, 3);
}

.contentList{
    display: flex;
    flex-direction: column;
    margin-left: 15px;
}
.cordonnerTitle{

}
.cordonnnerDesc{
    margin-top: 10px;
}

.error{
    color: red;
}

.success{
    color: rgb(1, 78, 1);
}



.right{
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.form{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.contactInput{
    outline: none;
    border: 0.5px solid lightgray;
    height: 30px;
    border-radius: 5px;
    padding-left: 10px;
}

.contactMessgae{
    outline: none;
    border: 0.5px solid lightgray;
    height: 100px;
    border-radius: 5px;
    padding-left: 10px;
    resize: none;
}

.contactButton{
    background-color: rgb(207, 177, 6);
    padding: 10px 20px;
    border-radius: 30px;
    font-size: 15px;
    border: none;
    font-weight: 900;
    cursor: pointer;
    border: 0.5px solid transparent;
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    right: 0;
    margin-left: auto;
}

.contactButton:hover:enabled{
    background-color: rgb(163, 139, 3);
    transition: all 0.1s ease;
}

.contactButton:disabled{
    cursor: auto;
}

.contactImg{
    width: 400px;
    opacity: 0.5;
}

/* .loading{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid black;
} */

.loadingImg{
    width: 20px;
}

a{
    text-decoration: none;
    color: var(--primary);
}