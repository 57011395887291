.slideContainer {
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: linear-gradient(217deg, rgba(30, 170, 226, 0.247), rgba(148, 205, 231, 0.267) 70.71%);
    position: relative;

}

.infoSlider {
    position: absolute;
    top: 40px;
    left: 170px;
}

.text1 {
    font-size: 50px;
    color: #fbc708;
    text-align: center;
    text-transform: uppercase;
}

.text2 {
    margin-top: 20px;
    margin-bottom: 50px;
    font-size: 20px;
    font-weight: bold;
    color: gray;
    text-align: center;
}

.slideContainer button {
    background-color: #1d3655;
    padding: 10px 20px;
    border-radius: 20px;
    font-size: 15px;
    border: none;
    font-weight: 900;
    cursor: pointer;
    border: 0.5px solid transparent;
    color: white;
    
}

.slideContainer button:hover {
    background-color: black;
    border: 0.5px solid orange;
    transition: all 0.1s ease;
    position: relative;
}

.imgSlider {
    width:100%;
    height: 600px;
    object-fit: cover;
}