.footerContainer {
    /* background-color: #5695b7; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    box-shadow: 2px 2px 10px 2px gray;
    color: gray;
}

.footerWraper {
    flex: 2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    
}

.leftFooter {
    flex: 1;
    display: flex;
    gap: 10px;
    align-items: center;
}

.rightFooter {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin-right: 30px;
    height: 100%;
    gap: 15px;
}

.copyright {
    flex: 1;
    margin-bottom: 15px;
}