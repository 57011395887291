.aboutContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('../../../public/img/bg.webp');
    background-size: cover;
    background-repeat: no-repeat;
}



.aboutWrapper{
    width: 900px;
    height: 100vh;
    background-color: white;
    padding: 50px;
    
}

.aboutContent{
    display: flex;
    line-height: 2;
}

.logoAbout{
    width: 150px;
    height: 150px;
}


