.containerNavbar {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 19px 10px -19px gray;
    -webkit-box-shadow: 0px 19px 10px -19px gray;
    -moz-box-shadow: 0px 19px 10px -19px gray;
    z-index: 9999;
    position: relative;
}

.logo {
    width: 80px;
}

.menu {
    width: 800px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.menuItem {
    margin-right: 40px;
    font-weight: 500;
    text-transform: uppercase;
    /* text-shadow: 2px 2px 4px black; */
}

.menu a {
    text-decoration: none;
    color: gray;
}

.menuItem:hover {
    color: rgb(41, 41, 41);
    transition: all 0.2s ease;
}

.active{
    font-weight: 900;
    text-decoration: underline;
}