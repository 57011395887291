.catalogueContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('../../../public/img/bg.webp');
    background-size: cover;
    background-repeat: no-repeat;
}



.catalogueWrapper{
    width: 900px;
    background-color: white;
    padding: 20px;
    padding-bottom: 200px;
}

.filmCards{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
    padding: 10px;
    margin-bottom: 30px;
    
}

.filmCard{
    display: flex;
    flex-direction: column;
    cursor: pointer;
    padding: 10px;
    border-radius: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    transition: all 0.3s ease;
    width: 230px;
}

.filmCard:hover{
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

/* .filmCard:hover .imgFilm{
    transform: scale(1.02);
} */


.imgFilm{
    width: 230px;
    height: 230px;
    object-fit: cover;
    margin-bottom: 10px;
}

.filmInfo{
display: flex;
flex-direction: column;
line-height: 1.5;
}

.filmTitle{
    font-size: 18px;
    font-weight: 700;
    color: rgb(61, 61, 61);
}

.filmDirector{
    font-size: 12px;
    color: gray;
}

.filmYear{
    font-size: 12px;
    color: gray;
}

.bfTitle{
    background: #e3e3e3;
     background: -moz-linear-gradient(top, #e3e3e3, #c8c8c8);
     background: --webkit-gradient(linear, left top, left bottom, from(#e3e3e3), to(#c8c8c8));
     padding: 10px 20px;
     margin-left: -40px;
     margin-top: 20px;
     margin-bottom: 20px;
     position: relative;
     width: 230px;
 
    -moz-box-shadow: 1px 1px 3px #292929;
    -webkit-box-shadow: 1px 1px 3px #292929;
       box-shadow: 1px 1px 3px #292929;
 
    color: #454545;
    text-shadow: 0 1px 0 white;
}

.arrow {
    width: 0; height: 0;
    line-height: 0;
    border-left: 20px solid transparent;
    border-top: 10px solid #808080;
    top: 100%;
    left: 0px;
    position: absolute;
}







h2 {
    width: 100%;
    text-align: center;
}
h2:after {
    display: inline-block;
    margin: 0 0 8px 20px;
    height: 3px;
    content: " ";
    background-color: #999;
    width: 140px;
}
h2:before {
    display: inline-block;
    margin: 0 20px 8px 0;
    height: 3px;
    content: " ";
    text-shadow: none;
    background-color: #999;
    width: 140px;
}



